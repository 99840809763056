<template>
    <div>
        <div
            @click="openInstruction"
            class="instruction p-1 mb-1 mx-2 px-2 bold"
            :class="{ active: instructions.isActiveItem(item) }"
        >
            {{ item.title }}

            <PlatonLink
                v-if="isAdmin && isMyProject(item)"
                class="small pr-1"
                tabindex="-1"
                :stop-propagation="true"
                :link="`forms/instructions/${item.id}?_target=modal`"
            >
                <i class="fa fa-cog"></i>
            </PlatonLink>

            <PlatonLink
                v-if="isAdmin && isMyProject(item)"
                class="small"
                tabindex="-1"
                :stop-propagation="true"
                :link="`forms/instructions/?_target=modal&parent_id=${item.id}`"
            >
                <i class="fa fa-plus"></i>
            </PlatonLink>
        </div>

        <div v-if="item.children" :style="{ 'padding-left': level * 10 + 'px' }">
            <instruction-menu
                :level="level + 1"
                :item="child"
                :key="index"
                v-for="(child, index) in item.children"
            ></instruction-menu>
        </div>
    </div>
</template>

<script>
/**
 * @property {Vue.Component} instructions
 */
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"

export default {
    name: "InstructionMenu",

    mixins: [PermissionMixin],

    props: {
        item: {},
        level: {
            default: 1
        }
    },

    inject: {
        instructions: {}
    },

    methods: {
        openInstruction() {
            this.instructions.openSubSection(this.item)
        }
    }
}
</script>

<style>
.instruction:hover,
.instruction.active {
    background: rgba(93, 183, 255, 0.2);
    border-radius: 0.25rem;
    font-weight: bold;
    cursor: pointer;
}
</style>
